<template>
  <v-card :flat="flat" class="pa-3">
    <v-sheet style="text-align: center;">
      <h3 v-if="!flat">
        {{ $t('create_a_team') }}!
      </h3>
      <div class="text-h4" v-else>
        {{ $t('one_more_step') }}
      </div>
      <!-- <div class="mt-2">
        {{ $t('fill_teams_details') }}
      </div> -->
      <div class="text-p mt-8">
        {{$t('give_join_code')}}
      </div>
      <join :embedded="true" style="max-width: 550px;"></join>
      <div class="text-h6 grey--text">
        {{ $t('or').toUpperCase() }}
      </div>
      <div class="text-p mt-7">
        {{$t('create_a_team')}}
      </div>
      <v-text-field
        :rounded="flat"
        class="mt-3"
        solo
        :placeholder="$t('team_name')"
        :label="$t('team_name')"
        autofocus
        v-model="team.team_name"
      ></v-text-field>
      <v-select
        :rounded="flat"
        solo
        :items="sports"
        item-text="sport_name"
        item-value="id"
        :placeholder="$t('choose_a_sport')"
        v-model="team.sport_id"
        persistent-hint
      >
      </v-select>
    </v-sheet>
    <v-card-actions class="px-10">
      <v-btn
        v-if="!inbound && !flat"
        @click="redirect"
      >
        {{ $t('back')}}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        :rounded="flat"
        :loading="loading"
        class="ml-3"
        color="primary"
        @click="save()"
      >
        {{ flat ? $t('start_analyzing') : $t('go')}}
      </v-btn>
      <v-spacer v-if="flat"></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Join from '@/components/team/Join.vue'

export default {
  props: ['flat'],
  components: { Join },
  created() {
    this.initSports()
      .then()
      .catch(e => this.error(e))

    this.initPlans()
  },
  data: () => ({
    team: {
      sport_id: 1,
      team_name: ''
    },
    loading: false
  }),
  methods: {
    save() {
      this.loading = true
      this.createTeam(this.team)
        .then(() => {
          if(this.inbound && this.plans && this.plans.length) {
            const plan = this.plans.find(plan => plan.short_name = this.$route.query.plan)

            this.goToProduct(plan.stripe_price_id)
            return
          }

          this.track('Team created', {
            ...this.team
          })

          this.success('Team created!')
          this.$router.push('/videos')
        })
        .catch(e => this.error(e))
        .finally(() => {
          this.loading = false
        })
    },
    ...mapActions([
      'initSports'
    ]),
    ...mapActions('team', [
      'createTeam'
    ]),
    ...mapActions('noti', [
      'error',
      'success'
    ]),
    ...mapActions('auth', [
      'initPlans'
    ]),
    ...mapActions('user', [
      'orderProduct'
    ]),
    goToProduct(price_id) {
      this.orderProduct({
        price_id: price_id
      })
        .then(e => {
          window.location = e.data.url
        })
        .catch(err => alert(err))
        .finally(() => this.loading = false)
    },
    redirect() {
      if(this.$route.query.from == 'settings') this.$router.push('/settings')
      else this.$router.push('/team/options')
    }
  },
  computed: {
    ...mapGetters([
      'sports',
      'mappedSports'
    ]),
    ...mapGetters('auth', [
      'plans'
    ]),
    inbound() {
      return !!this.$route.query?.plan
    },
  }
}
</script>
